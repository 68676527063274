import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface GeoLocationResponse {
    country: string;
}

@Injectable({
    providedIn: 'root'
})
export class GeoLocationService {
    private apiUrl = 'https://ipapi.co/json/';

    constructor(private http: HttpClient) {}

    getUserCountry(): Observable<string> {
        return this.http.get<GeoLocationResponse>(this.apiUrl).pipe(
            map(response => response.country)
        );
    }
}
