import { APP_INITIALIZER, Component, inject, OnInit, SecurityContext } from '@angular/core';
import { BasicComponent } from './views/basic/basic.component';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ArticleDetailComponent } from './views/article/article-detail.component';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { DatocmsService } from './views/basic/services/datocms.service';
import { HttpHeaders } from '@angular/common/http';
import { MarkdownService, SECURITY_CONTEXT } from 'ngx-markdown';
import { GeoLocationService } from "./views/basic/services/geoLocation.service";

const headers = new HttpHeaders().set('Authorization', `Bearer 47a622b54634a2916584897b46c56d`);

@Component({
  standalone: true,
  selector: 'app-root',
  imports: [
    BasicComponent,
    ArticleDetailComponent,
    RouterModule,
    NavigationComponent,
    TranslateModule,
    AsyncPipe,
    MatProgressSpinner,
    ApolloModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (translate: TranslateService) => {
        return () => translate.use('pl').toPromise();
      },
      deps: [TranslateService],
      multi: true
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: 'https://graphql.datocms.com/',
            headers: headers,
          })
        };
      },
      deps: [HttpLink]
    },
    { provide: SECURITY_CONTEXT, useValue: SecurityContext.NONE },
    MarkdownService,
    DatocmsService,
    GeoLocationService,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private translateService: TranslateService = inject(TranslateService);
  private geoLocationService: GeoLocationService = inject(GeoLocationService);
  private router: Router = inject(Router);

  public title = 'BIM Monuments';
  public loading$ = new BehaviorSubject<boolean>(true);


  ngOnInit(): void {
    const savedLang = localStorage.getItem('lang');

    if (!savedLang) {
      const currentDomain = window.location.hostname;

      if (currentDomain.endsWith('.pl')) {
        localStorage.setItem('lang', 'pl');
      }
      else if (currentDomain.endsWith('.com')) {
        localStorage.setItem('lang', 'en');
      }
    }

    this.translateService.use(localStorage.getItem('lang') || this.translateService.getDefaultLang()).subscribe(() => {
      this.loading$.next(false);
    });

    this.geoLocationService.getUserCountry().subscribe(country => {
      if (country === 'IN') {
        this.router.navigate(['/404']);
      } else {
        this.translateService.use(localStorage.getItem('lang') || this.translateService.getDefaultLang()).subscribe(() => {
          this.loading$.next(false);
        });
      }
    });
  }
}
